//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'
import PDFObject from 'pdfobject';

export default class extends Controller
{
  static values = { src: String, title: String };

  connect()
  {
    if( this.hasSrcValue && PDFObject.supportsPDFs )
    {
      this.element.innerHTML = '';
      useIntersection( this );
    }
  }

  appear( elt )
  {
    PDFObject.embed( this.srcValue, this.element, { title: this.titleValue, fallbackLink: false } );
  }
}