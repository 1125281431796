//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Application } from '@hotwired/stimulus';
const application = Application.start()

application.debug = false
window.Stimulus   = application

export { application }
