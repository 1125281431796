//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { application } from "./application"

import BenutzerPasswordController from "./benutzer_password_controller"
application.register( 'benutzer-password', BenutzerPasswordController )

import ClockController from "./clock_controller"
application.register( 'clock', ClockController )

import DatepickerController from "./datepicker_controller"
application.register( 'datepicker', DatepickerController )

import FileFieldController from "./file_field_controller"
application.register( 'file-field', FileFieldController )

import FileUploadController from "./file_upload_controller"
application.register( 'file-upload', FileUploadController )

import IpeController from "./ipe_controller"
application.register( 'ipe', IpeController )

import KalenderController from "./kalender_controller"
application.register( 'kalender', KalenderController )

import ListController from "./list_controller"
application.register( 'list', ListController )

import ModalController from "./modal_controller"
application.register( 'modal', ModalController )

import NotificationController from "./notification_controller"
application.register( 'notification', NotificationController )

import PdfController from "./pdf_controller"
application.register( 'pdf', PdfController )

import SelectController from "./select_controller"
application.register( 'select', SelectController )

import TabController from "./tab_controller"
application.register( 'tab', TabController )

import TableController from "./table_controller"
application.register( 'table', TableController )

import TagsController from "./tags_controller"
application.register( 'tags', TagsController )

import ZeitDauerController from "./zeit_dauer_controller"
application.register( 'zeit-dauer', ZeitDauerController )