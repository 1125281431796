//
// Co3
//
// Copyright:: (c) 2021 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap';

export default class extends Controller
{
  initialize()
  {
    this.hideEvent = this._hideEvent.bind( this );
  }

  connect()
  {
    this.toast = new bootstrap.Toast( this.element );
    this.toast.show();
  }

  _hideEvent()
  {
    if( this.toast )
    {
      this.toast.hide();
    }
  }
}