//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"

export default class extends Controller
{
  static targets = [ 'img', 'action', 'select', 'reset', 'delete', 'form', 'input', 'inputRemove' ];
  static values  = { emptyUrl: String, lastUrl: String, autosubmit: Boolean };
  static classes = [ 'btnEnable', 'btnDisable', 'dragOver' ];

  connect()
  {
    // Hide file input and show action buttons
    this.inputTarget.style.display = 'none';

    // Show actions
    if( this.hasActionTarget )
    {
      this.actionTarget.style.display = 'block';
    }

    // Disable reset
    this.disableReset();

    // Remember last URL
    if( this.hasImgTarget )
    {
      if( this.imgTarget.getAttribute( 'src' ) )
      {
        this.lastUrlValue = this.imgTarget.src;
        this.enableRemove();
      }
      else
      {
        this.imgTarget.src = this.emptyUrlValue;
        this.disableRemove();
      }
    }

    if( ! this.hasAutosubmitValue )
    {
      this.autosubmitValue = !this.hasImgTarget;
    }
  }

  select( e )
  {
    e.preventDefault();
    this.inputTarget.click();
  }

  upload( e )
  {
    e.preventDefault();

    if( this.inputTarget.files && this.inputTarget.files[0] )
    {
      if( this.hasImgTarget )
      {
        const reader = new FileReader();

        reader.onload = ( e ) =>
        {
          this.imgTarget.src = e.target.result;
          this.inputRemoveTarget.value = '';
          this.enableReset();
          this.enableRemove();
        }

        reader.readAsDataURL( this.inputTarget.files[0] );
      }

      if( this.autosubmitValue )
      {
        this.inputTarget.form.submit();
      }
    }
  }

  reset( e )
  {
    e.preventDefault();

    this.disableReset();
    this.enableRemove();

    this.inputTarget.value = '';
    this.inputRemoveTarget.value = '';
    this.imgTarget.src = this.lastUrlValue;
  }

  delete( e )
  {
    e.preventDefault();

    this.disableRemove();
    this.enableReset();

    this.inputTarget.value = '';
    this.inputRemoveTarget.value = '1';
    this.imgTarget.src = this.emptyUrlValue;
  }


  disableReset()
  {
    if( this.hasBtnEnableClass )
    {
      this.resetTarget.classList.remove( this.btnEnableClass );
    }

    if( this.hasBtnDisableClass )
    {
      this.resetTarget.classList.add( this.btnDisableClass );
    }
  }

  enableReset()
  {
    if( this.lastUrlValue )
    {
      if( this.hasBtnDisableClass )
      {
        this.resetTarget.classList.remove( this.btnDisableClass );
      }

      if( this.hasBtnEnableClass )
      {
        this.resetTarget.classList.add( this.btnEnableClass );
      }
    }
  }

  disableRemove()
  {
    if( this.hasBtnEnableClass )
    {
      this.deleteTarget.classList.remove( this.btnEnableClass );
    }

    if( this.hasBtnDisableClass )
    {
      this.deleteTarget.classList.add( this.btnDisableClass );
    }
  }

  enableRemove()
  {
    if( this.hasBtnDisableClass )
    {
      this.deleteTarget.classList.remove( this.btnDisableClass );
    }

    if( this.hasBtnEnableClass )
    {
      this.deleteTarget.classList.add( this.btnEnableClass );
    }
  }


  dragOver( e )
  {
    e.preventDefault();
    e.stopPropagation();
    this.highlightOn();
  }

  dragLeave( e )
  {
    e.preventDefault();
    e.stopPropagation();
    this.highlightOff();
  }

  dragDrop( e )
  {
    e.preventDefault();
    e.stopPropagation();
    this.highlightOff();

    if( e.dataTransfer && e.dataTransfer.files )
    {
      this.inputTarget.files = e.dataTransfer.files;
      this.upload( e );
    }
  }

  highlightOn()
  {
    if( this.hasDragOverClass )
    {
      this.element.classList.add( this.dragOverClass );
    }
  }

  highlightOff()
  {
    if( this.hasDragOverClass )
    {
      this.element.classList.remove( this.dragOverClass );
    }
  }
}
