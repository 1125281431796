//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"

export default class extends Controller
{
  static targets = [ 'tab', 'tabContent' ];
  static values = { tab: String };
  static classes = [ 'active' ];

  connect()
  {
    if( window.location.hash != "" )
    {
      this.tabValue = window.location.hash;
    }
    else if( ! this.hasTabValue )
    {
      this.tabValue = this.tabTarget.hash;
    }
  }

  // Action: Tab selected
  select( e )
  {
    this.tabValue = e.currentTarget.hash;
  }

  // Callback if tab changed
  tabValueChanged()
  {
    this.update();
  }

  // Update view
  update()
  {
    const tab = this.tabValue.substring( 1 );
    const activeClass = this.hasActiveClass ? this.activeClass : 'active';

    this.tabContentTargets.forEach( content =>
    {
      if( content.id == this.tabValue.substring( 1 ) )
      {
        content.classList.add( activeClass, 'show' );
      }
      else
      {
        content.classList.remove( activeClass, 'show' );
      }
    });

    this.tabTargets.forEach( tab =>
    {
      if( tab.hash == this.tabValue )
      {
        tab.classList.add( activeClass );
      }
      else
      {
        tab.classList.remove( activeClass );
      }
    });
  }
}