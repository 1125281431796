//
// Co3
//
// Copyright:: (c) 2024 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import multiMonthPlugin from '@fullcalendar/multimonth';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import deLocale from '@fullcalendar/core/locales/de';

import { put } from '@rails/request.js';

export default class extends Controller
{
  static values = { eventUrl: Array, resUrl: String, nav: { type: Boolean, default: true }, date: String, dateStart: String, dateEnd: String };

  connect()
  {
    let options;

    if( this.hasResUrlValue )
    {
      options = this.timelineOptions;
      options[ 'resources' ] = this.resUrlValue;
    }
    else if( this.navValue )
    {
      options = this.navOptions;
    }
    else
    {
      options = this.calendarOptions;
    }

    if( this.hasEventUrlValue )
    {
      options[ 'eventSources' ] = this.eventUrlValue;
    }

    if( this.hasDateValue )
    {
      options[ 'initialDate' ] = this.dateValue;
    }

    if( this.hasDateStartValue && this.hasDateEndValue )
    {
      options[ 'validRange' ] = { start: this.dateStartValue, end: this.dateEndValue }
    }

    this.calendar = new Calendar( this.element, options );
    this.calendar.render();
  }

  async event_change( info )
  {
    const data = { von: info.event.start, bis: info.event.end };
    const response = await put( info.event.extendedProps.api_url, { body: JSON.stringify( data ) } );
    if( response.ok )
    {
      this.getEventSources()[ 0 ].refetch();
    }
    else
    {
      info.revert();
    }
  }

  get navOptions()
  {
    return {
      plugins: [ dayGridPlugin, timeGridPlugin, bootstrap5Plugin ],
      themeSystem: 'bootstrap5',
      height: '100%',
      locale: deLocale,
      headerToolbar:
      {
        left: 'prev,today,next',
        center: 'title',
        right: 'timeGridWeek,dayGridMonth'
      },
      buttonIcons:
      {
        today: 'calendar',
        dayGridMonth: 'calendar-week',
        timeGridWeek: 'calendar-range'
      },
      initialView: 'timeGridWeek',
      weekNumbers: true,
      businessHours:
      [
        {
          daysOfWeek: [ 1, 2, 3, 4 ],
          startTime: '07:00',
          endTime: '17:00'
        },
        {
          daysOfWeek: [ 5 ],
          startTime: '07:00',
          endTime: '16:00'
        }
      ]
    };
  }

  get calendarOptions()
  {
    return {
      plugins: [ listPlugin, timeGridPlugin, dayGridPlugin, interactionPlugin, bootstrap5Plugin ],
      themeSystem: 'bootstrap5',
      height: '100%',
      locale: deLocale,
      headerToolbar:
      {
        left: 'prev,next',
        center: 'title',
        right: 'listYear,timeGridWeek,dayGridMonth'
      },
      buttonIcons:
      {
        listYear: 'list',
        timeGridWeek: 'calendar-range',
        dayGridMonth: 'calendar-week'
      },
      initialView: 'listYear',
      weekNumbers: true,
      eventResizableFromStart: true,
      eventClick: this.event_click,
      eventResize: this.event_resize,
      eventChange: this.event_change
    };
  }

  get timelineOptions()
  {
    return {
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [ resourceTimelinePlugin, listPlugin, bootstrap5Plugin ],
      themeSystem: 'bootstrap5',
      height: '100%',
      locale: deLocale,
      headerToolbar:
      {
        left: 'prev,next',
        center: 'title',
        right: 'listYear,resourceTimelineWeek,resourceTimelineMonth'
      },
      buttonIcons:
      {
        listYear: 'list',
        resourceTimelineWeek: 'calendar-week',
        resourceTimelineMonth: 'calendar-range',
      },
      initialView: 'resourceTimelineMonth',
    };
  }
}