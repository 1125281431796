//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

export default class extends Controller
{
  initialize()
  {
    this.ss = null;
  }

  connect()
  {
    this.ss = new SlimSelect( { select: this.element } );
  }

  disconnect()
  {
    this.ss = null;
  }

  focus( e )
  {
    this.ss.open();
  }
}