//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import Tags from 'bootstrap5-tags';

export default class extends Controller
{
  connect()
  {
    this.tags = new Tags( this.element,
    {
      badgeStyle: 'secondary',
      allowClear: true,
      allowNew: true
    });
  }
}