//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"

export default class extends Controller
{
  static targets = [ 'input' ];
  static classes = [ 'draging', 'active' ];

  connect()
  {
    if( this.hasDropTarget )
    {
      // Connect drag events
      this.dropTarget.addEventListener( 'dragenter', this.dragOver.bind(  this ));
      this.dropTarget.addEventListener( 'dragover',  this.dragOver.bind(  this ));
      this.dropTarget.addEventListener( 'dragleave', this.dragLeave.bind( this ));
      this.dropTarget.addEventListener( 'drop',      this.dragDrop.bind(  this ));
    }
  }

  select( e )
  {
    e.preventDefault();
    this.inputTarget.click();
  }

  upload( e )
  {
    e.preventDefault();

    if( this.inputTarget.files && this.inputTarget.files[0] )
    {
      this.activeStart();
      this.inputTarget.form.submit();
    }
  }

  dragOver( e )
  {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
    this.dragStart();
  }

  dragLeave( e )
  {
    e.preventDefault();
    e.stopPropagation();
    this.dragStop();
  }

  dragDrop( e )
  {
    e.preventDefault();
    e.stopPropagation();
    this.dragStop();

    if( e.dataTransfer && e.dataTransfer.files )
    {
      this.inputTarget.files = e.dataTransfer.files;
      this.upload( e );
    }
  }

  dragStart()
  {

    if( this.hasDragingClass )
    {
      this.element.classList.add( ...this.dragingClasses );
    }
    else
    {
      this.element.classList.add( 'draging' );
    }
  }

  dragStop()
  {
    if( this.hasDragingClass )
    {
      this.element.classList.remove( ...this.dragingClasses );
    }
    else
    {
      this.element.classList.remove( 'draging' );
    }
  }

  activeStart()
  {
    if( this.hasActiveClass )
    {
      this.element.classList.add( ...this.activeClasses );
    }
    else
    {
      this.element.classList.add( 'active' );
    }
  }

  activeStop()
  {
    if( this.hasActiveClass )
    {
      this.element.classList.add( ...this.activeClasses );
    }
    else
    {
      this.element.classList.add( 'active' );
    }
  }

  get hasDropTarget()
  {
    return true;
  }

  get dropTarget()
  {
    return document;
  }
}