//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller
{
  static targets = [ 'list', 'next' ];

  initialize()
  {
    this.observerNext = this.createNextObserver();
    this.observedNext = null;
  }

  async load( url )
  {
    if( url == null ) return;
    const response = await get( url, { contentType: "text/vnd.turbo-stream.html", responseKind: "turbo-stream" } );
    return response.ok;
  }

  createNextObserver()
  {
    const options = { root: this.element };

    return new IntersectionObserver( items =>
    {
      items.forEach( item =>
      {
        if( item.isIntersecting )
        {
          this.observerNext.unobserve( item.target );
          const url = item.target.dataset.url;
          this.load( url );
        }
      });
    }, options );
  }

  nextTargetConnected( element )
  {
    this.observerNext.observe( element );
  }

  nextTargetDisconnect( element )
  {
    this.observerNext.unobserve( element );
  }
}