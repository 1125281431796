//
// Co3
//
// Copyright:: (c) 2022 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus";
import { patch } from '@rails/request.js'

export default class extends Controller
{
  static targets = [ 'zeitVon', 'zeitBis', 'zeitPause', 'zeitDauer' ];
  static values = { 'url': String };

  initialize()
  {
    this.updateEvent = this.update.bind( this );
  }

  zeitVonTargetConnected( element )
  {
    element.addEventListener( 'blur', this.updateEvent );
  }

  zeitBisTargetConnected( element )
  {
    element.addEventListener( 'blur', this.updateEvent );
  }

  zeitPauseTargetConnected( element )
  {
    element.addEventListener( 'blur', this.updateEvent );
  }

  zeitDauerTargetConnected( element )
  {
    element.addEventListener( 'change', this.updateEvent );
  }

  async update( event )
  {
    const requestData = new FormData( this.element );
    const response = await patch( this.url, { body: requestData, responseKind: 'json' } );
    if( response.ok )
    {
      const responseData = await response.json;

      if( this.hasZeitVonTarget && 'zeit_von' in responseData )
      {
        this.zeitVonTarget.value = responseData.zeit_von;
      }

      if( this.hasZeitBisTarget && 'zeit_bis' in responseData )
      {
        this.zeitBisTarget.value = responseData.zeit_bis;
      }

      if( this.hasZeitPauseTarget && 'zeit_pause' in responseData )
      {
        this.zeitPauseTarget.value = responseData.zeit_pause;
      }

      if( this.hasZeitDauerTarget && 'zeit_dauer' in responseData )
      {
        this.zeitDauerTarget.value = responseData.zeit_dauer;
      }
    }
  }

  get url()
  {
    return this.hasUrlValue ? this.urlValue : this.element.action;
  }
}