//
// Co3
//
// Copyright:: (c) 2024 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus";

export default class extends Controller
{
  static targets = [ 'date', 'time' ];
  static values = { locale: String };

  initialize()
  {
    this.timeFormat = new Intl.DateTimeFormat( this.locale, { timeStyle: "short" } );
    this.dateFormat = new Intl.DateTimeFormat( this.locale, { day: "numeric", month: "long", year: "numeric" } );
  }

  connect()
  {
    this.interval = setInterval( () =>
    {
      if( this.hasDateTarget )
      {
        this.dateTarget.innerHTML = this.dateFormat.format( new Date() );
      }

      if( this.hasTimeTarget )
      {
        this.timeTarget.innerHTML = this.timeFormat.format( new Date() );
      }
    }, 1000 );
  }

  get locale()
  {
    return this.hasLocaleValue ? this.localeValue : 'de-CH';
  }
}