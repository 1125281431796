//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

export default class extends Controller
{
  initialize()
  {
    this._submitEvent = this.submitEvent.bind( this );
    this._hiddenEvent = this.hiddenEvent.bind( this );
  }

  connect()
  {
    this.element.addEventListener( 'turbo:submit-end', this._submitEvent );
    this.element.addEventListener( 'hidden.bs.modal', this._hiddenEvent );

    this.modal = new bootstrap.Modal( this.element );
    this.modal.show();
  }

  disconnect()
  {
    this.element.removeEventListener( 'turbo:submit-end', this._submitEvent );
    this.element.removeEventListener( 'hidden.bs.modal', this._hiddenEvent );
    this.modal.hide();
  }

  submitEvent( e )
  {
    if( e.detail.success )
    {
      this.hide();
    }
  }

  hide()
  {
    this.modal.hide();
  }

  hiddenEvent( e )
  {
    this.remove();
  }

  remove()
  {
    this.element.closest( 'turbo-frame' ).removeAttribute( 'src' );
    this.element.remove();
  }
}