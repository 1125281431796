//
// Co3
//
// Copyright:: (c) 2021 Innotronic Ingenieurbüro GmbH

import { Controller } from "@hotwired/stimulus"
import Litepicker from 'litepicker';

export default class extends Controller
{
  static values  = { language: String, format: String, weekNumbers: Boolean };

  initialize()
  {
    this.keyEvent = this._keyEvent.bind( this );
  }

  connect()
  {
    this.picker = new Litepicker(
    {
      element: this.element,
      lang: this.language,
      format: this.format,
      showWeekNumbers: this.showWeekNumbers
    });

    this.picker.on( 'show', () => { document.addEventListener( 'keyup', this.keyEvent ) } );
    this.picker.on( 'hide', () => { document.removeEventListener( 'keyup', this.keyEvent ) } );
  }

  cancel( e )
  {
    this.picker.hide();
  }

  _keyEvent( e )
  {
    switch( e.key )
    {
      case 'Esc':
      case 'Escape':
        this.cancel( e );
        break;
    }
  }

  get format()
  {
    if( this.hasFormatValue )
    {
      return this.formatValue;
    }
    else
    {
      return 'DD.MM.YYYY';
    }
  }

  get language()
  {
    if( this.hasLanguageValue )
    {
      return this.languageValue;
    }
    else
    {
      return 'de-CH';
    }
  }

  get showWeekNumbers()
  {
    if( this.hasWeekNumbersValue )
    {
      return this.weekNumbersValue;
    }
    else
    {
      return true;
    }
  }
}